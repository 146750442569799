<template>
  <transition name="fade">
    <div
      v-if="showBanner"
      class="banner-area has-text-centered has-background-black has-text-white p-2"
    >
      <strong class="has-text-white">😊 {{ message }} 😊</strong>
      <button
        class="delete is-small ml-3"
        @click="hideBanner"
        aria-label="Close"
      ></button>
    </div>
  </transition>
</template>

<script>
export default {
  name: "BannerArea",
  props: {
    message: {
      type: String,
      default: "",
    },
    showBanner: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    hideBanner() {
      // One approach: simply emit an event to the parent to toggle off
      this.$emit("update:showBanner", false);
    },
  },
};
</script>

<style scoped>
.banner-area {
  position: sticky;
  top: 0;
  z-index: 20; /* Ensure it's above the navbar below */
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
