<template>
  <b-modal
    v-model="isCardModalActive"
    :width="640"
    scroll="keep"
    @close="handleClose"
  >
    <div class="card">
      <div class="card-content has-text-centered">
        <img
          class="fh_welcome_message_modal_img"
          src="/img//selle_app_store_1.png"
          alt="Image"
        />

        <div class="content">
          <p class="subtitle is-5">
            Discover the best user experience with our app
          </p>
          <AppLinks icon_type="rectangle" />
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
import { defineAsyncComponent } from "vue";
export default {
  name: "WelcomeMessage",
  components: {
    AppLinks: defineAsyncComponent(() =>
      import("@/components/common/AppLinks.vue")
    ),
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isCardModalActive: this.showModal,
    };
  },
  watch: {
    showModal(newVal) {
      this.isCardModalActive = newVal;
    },
    isCardModalActive(newVal) {
      if (!newVal) {
        this.$emit("update:showModal", false);
      }
    },
  },
  methods: {
    handleClose() {
      this.isCardModalActive = false;
    },
  },
};
</script>

<style scoped>
.fh_welcome_message_modal_img {
  max-width: 25%;
  text-align: center;
}
</style>
