<template>
  <Nav />
  <div class="container findhub_home_container_fluid">
    <div class="columns">
      <div class="column">
        <!-- <h3>User: {{ $currentUser() }}</h3> -->
        <div class="fh_home_header_title_and_applink">
          <GreetingsMessage />
          <AppLinks />
        </div>
        <LocationSelector
          :selectedLocation="selectedLocation"
          :locationQuery="locationQuery"
          :googleAutocompleteDisplay="googleAutocompleteDisplay"
          @update:selectedLocation="selectedLocation = $event"
          @update:locationQuery="locationQuery = $event"
        />
      </div>
    </div>
    <div class="columns findhub_home_container-main is-align-items-center">
      <div class="column">
        <div class="box findhub_home_div_box">
          <b-tabs v-model="activeTab" class="fh_home_search_tabs-main">
            <b-tab-item
              label="Quick Search"
              icon-pack="mdi"
              icon="mdi mdi-magnify"
            >
              <SearchInputCustom />
            </b-tab-item>
            <b-tab-item label="Image" icon-pack="mdi" icon="mdi mdi-image">
              <ImageSearchComponent />
            </b-tab-item>
          </b-tabs>
        </div>
      </div>
    </div>
    <HomeCategories />
    <RecentSearche />
  </div>
  <FooterHome />
</template>

<script>
/* eslint-disable */
import { defineAsyncComponent } from "vue";
import { useAuthStore, useLocationStore, usePageStore } from "../store";
import _ from "lodash";
import { MapUtil } from "../utils/map.util";
export default {
  components: {
    SearchInputCustom: defineAsyncComponent(() =>
      import("../components/SearchInputCustom.vue")
    ),
    Nav: defineAsyncComponent(() =>
      import("@/components/Nav.vue")
    ),
    GreetingsMessage: defineAsyncComponent(() =>
      import("@/components/common/GreetingsMessage.vue")
    ),
    AppLinks: defineAsyncComponent(() =>
      import("@/components/common/AppLinks.vue")
    ),
    RecentSearche: defineAsyncComponent(() =>
      import("@/components/common/RecentSearche.vue")
    ),
    LocationSelector: defineAsyncComponent(() =>
      import("@/components/common/LocationSelector.vue")
    ),
    HomeCategories: defineAsyncComponent(() =>
      import("@/components/common/HomeCategories.vue")
    ),
    LoginModal: defineAsyncComponent(() =>
      import("@/components/LoginModal.vue")
    ),
    ImageSearchComponent: defineAsyncComponent(() =>
      import("@/components/search/ImageSearchComponent.vue"),
    ),
    FooterHome: defineAsyncComponent(() =>
      import("@/views/layout/FooterHome.vue")
    ),
  },
  computed: {
    filteredLocationSelect() {
      return this.locationStore.userLocations;
    },
  },
  created() {
    this.authStore = useAuthStore();
    this.locationStore = useLocationStore();
    this.pageStore = usePageStore();
  },
  data() {
    return {
      activeTab: 0,
      locationQuery: "",
      googleAutoComplete: null,
      locationInput: null,
      mobileOS: this.isMobile(),
      googleAutocompleteDisplay: false,
    };
  },

  methods: {
    isMobile() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
      }
      if (/android/i.test(userAgent)) {
        return "Android";
      }
      return false;
    },
    loadGoogleMapsScript() {
      return new Promise((resolve, reject) => {
        window.initMap = resolve;
        const script = document.createElement("script");
        script.src = `https://maps.googleapis.com/maps/api/js?libraries=places&key=${process.env.VUE_APP_GOOGLE_API_KEY}&language=en&callback=initMap`;
        script.async = true;
        script.setAttribute("loading", "lazy");
        script.onerror = reject;
        document.head.appendChild(script);
      });
    },
    // initializeGoogleAutocomplete() {
    //   if (this.googleAutocompleteDisplay) {
    //     const inputElement = this.$refs.locationInput?.$el?.querySelector('input');

    //     if (inputElement instanceof HTMLInputElement) {
    //       this.googleAutoComplete = new google.maps.places.Autocomplete(inputElement, {
    //         types: ["(regions)"],
    //         componentRestrictions: { country: "AE" },
    //       });

    //       this.googleAutoComplete.addListener("place_changed", () => {
    //         const placeInfo = this.googleAutoComplete.getPlace();
    //         if (placeInfo && _.has(placeInfo, "address_components")) {
    //           const placeData = MapUtil.parseAddressComponents(
    //             placeInfo.address_components
    //           );
    //           this.locationStore.setCurrentLocation(
    //             placeInfo.geometry.location.lat(),
    //             placeInfo.geometry.location.lng(),
    //             MapUtil.getLocalDisplayName(placeData)
    //           );
    //           console.log("Location updated successfully");
    //         }
    //       });
    //     } else {
    //       console.error("locationInput is not a valid input element");
    //     }
    //   }
    // },
  },
  beforeMount() {
    // console.log("Initializing LocationSelect");
    this.locationStore.checkAndRequestLocation();
  },
  async mounted() {
    try {
      await this.loadGoogleMapsScript();
      // this.initializeGoogleAutocomplete();
      // this.authStore.setLocationSelector();
      // wait
      // this.locationStore.registerFCMToken();
      // await this.updateLocationSelect();
       } catch (error) {
      console.error("Error during component mount:", error);
    }
    this.mobileOS = this.isMobile();
    await this.pageStore.getAllCategories();
  },


};
</script>

<style scoped>
/* General Styles */
.left-column,
.right-column {
  flex: 1;
}

.findhub_home_container_fluid {
  display: flex;
  flex-direction: column;
  padding: 2rem 2rem;
  flex-grow: 1;
  justify-content: space-between;
}


.fh_home_header_title_and_applink {
  display: flex;
  justify-content: space-between;
}


.store-icon {
  width: 3rem;
}

.fh_main-location-refresh_btn {
  cursor: pointer;
  margin-left: 2px;
}

.fh_home_location_selection-dropdown {
  position: relative;
}


.animated-arrow {
  animation: bounce 1s infinite;
  position: absolute;
  top: 0;
  right: -3rem;
}

.findhub_home_div_box {
  height: auto;
}





/* Media Queries */
@media (max-width: 600px) {
  .findhub_home_container_fluid {
    padding: .5rem .5rem;
  }

  .fh_home_greetings_container {
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 1.125;
  }

  .findhub_home_div_box {
    padding: .1rem;
  }

  .fh_home_header_title_and_applink {
    margin-top: 1rem;
  }


  .findhub_home_container-product_tile-item {
    width: 4rem;
    height: 4rem;
    padding: .5rem .7rem;
    word-break: break-word;
  }

  .category-name {
    font-size: .7rem;
  }

}

@keyframes bounce {

  0%,
  100% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(-10px);
  }
}
</style>
