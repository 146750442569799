<template>
  <footer class="footer fh_app_main_footer">
    <div class="content fh_footer_bottom_container">
      <p class="mb-0">
        Copyrate 2024 FindHub IT Solutions FZC. All rights reserved.
      </p>
      <div class="social-media-icons">
        <a href="#" target="_blank" rel="noopener noreferrer">
          <span :class="['mdi', iconClass, 'mdi-facebook']"></span>
        </a>
        <a href="#" target="_blank" rel="noopener noreferrer">
          <span :class="['mdi', iconClass, 'mdi-twitter']"></span>
        </a>
        <a href="#" target="_blank" rel="noopener noreferrer">
          <span :class="['mdi', iconClass, 'mdi-instagram']"></span>
        </a>
        <a href="#" target="_blank" rel="noopener noreferrer">
          <span :class="['mdi', iconClass, 'mdi-linkedin']"></span>
        </a>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterHome",
  data() {
    return {
      links: [
        { to: "/what-we-do", text: "What We Do" },
        { to: "/privacy-policy", text: "Privacy Policy" },
        { to: "/terms-of-service", text: "Terms of Service" },
        { to: "/refund-policy", text: "Refund Policy" },
        { to: "/faq", text: "FAQ" },
        { to: "/join-as-seller", text: "Join as a Seller" },
        { to: "/blog", text: "Blog" },
      ],
    };
  },
  computed: {
    iconClass() {
      return window.innerWidth < 600 ? "mdi-18px" : "mdi-24px";
    },
  },
};
</script>

<style scoped>
.findhub_footer_store-icon {
  max-width: 8rem;
  margin-right: 1rem;
}

.findhub_footer_brand-logo {
  max-width: 5rem;
  filter: brightness(0);
}

.fh_app_main_footer {
  padding: 1rem 1rem;
  background-color: transparent !important;
}

.fh_footer_bottom_container {
  display: flex;
  justify-content: space-between;
}

.social-media-icons a {
  color: #122435;
}
@media (max-width: 640px) {
  /* Adjust the max-width as needed for your mobile breakpoint */
  .fh_app_main_footer {
    padding: 0.4rem 0rem;
    /* Padding for small screens */
    /* font-size: 0.8rem; */
  }
  .fh_footer_bottom_container {
    display: flex;
    flex-direction: column;
    font-size: 0.8rem;
  }
}
@media (max-width: 1024px) {
  /* Tablet breakpoint adjustments */

  .fh_footer_bottom_container {
    display: flex;
    flex-direction: row;
    /* Change back to row for tablets */
  }
}

/* Add any additional Tailwind classes if needed */
</style>
