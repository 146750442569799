<template>
  <footer class="footer has-text-centered">
    <div class="content">
      <p>&copy; 2024 My Awesome App</p>
    </div>
  </footer>
</template>

<script>
export default {
  name: "TheFooter",
};
</script>

<style scoped>
/* 
  If you want the footer to be truly “sticky” at the bottom of the viewport, 
  that requires a slightly different approach (flex layouts, etc.).
  For now, it will just appear after main content.
*/
</style>
