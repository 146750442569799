<template>
  <div class="home-layout">
    <!-- TOP BANNER (ABOVE THE NAVBAR) -->
    <BannerArea :message="globalMessage" v-model:showBanner="showBanner" />

    <!-- STICKY NAVBAR, OFFSET BELOW BANNER IF IT’S VISIBLE -->
    <FixedNavbar :topOffset="navbarOffset" />

    <!-- MAIN CONTENT AREA -->
    <section class="section fh_main-content">
      <GreetingsMessage />
    </section>

    <!-- FOOTER AT THE BOTTOM -->
    <TheFooter />
  </div>
</template>

<script>
import BannerArea from "@/components/layout/BannerArea.vue";
import FixedNavbar from "@/components/layout/FixedNavbar.vue";
import TheFooter from "@/components/layout/TheFooter.vue";
import GreetingsMessage from "@/components/common/GreetingsMessage.vue";
export default {
  name: "HomePage", // or MainLayout
  components: {
    BannerArea,
    FixedNavbar,
    TheFooter,
    GreetingsMessage,
  },
  data() {
    return {
      showBanner: true, // toggle this on/off to show/hide banner
      globalMessage: "Welcome! This is preview version of the app.",
    };
  },
  computed: {
    // If banner is shown, push navbar down 3rem (example). Otherwise, top:0.
    navbarOffset() {
      return this.showBanner ? "3rem" : "1.5rem";
    },
  },
};
</script>

<style scoped>
.home-layout {
  /* If using sticky positioning, no special top padding is strictly necessary. 
     The banner & navbar handle their own offset. */
}

.section.fh_main-content {
  /* Give some spacing so content doesn’t end up behind a fixed/sticky navbar. */
  margin-top: 1rem;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.2),
      rgba(255, 255, 255, 0.2)
    ),
    radial-gradient(
      77.36% 256.97% at 77.36% 57.52%,
      rgb(238, 239, 175) 0%,
      rgb(195, 227, 250) 100%
    );
  clip-path: ellipse(150% 87% at 93% 13%);
}

/* 
  If you prefer a full "app-like" height layout:
  
  html,
  body,
  .home-layout {
    height: 100%;
    margin: 0;
    overflow-x: hidden;
  }
*/
</style>
