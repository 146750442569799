<template>
  <!-- We pass :style="navbarStyle" to dynamically set top offset -->
  <nav
    class="navbar fh_navbar"
    role="navigation"
    aria-label="main navigation"
    :style="navbarStyle"
  >
    <div class="navbar-brand">
      <a class="navbar-item" href="#">
        <img :src="require('@/assets/logo_svg.svg')" alt="Logo" />
      </a>
      <a
        role="button"
        class="navbar-burger"
        :class="{ 'is-active': isActive }"
        @click="toggleNavbar"
        aria-label="menu"
        aria-expanded="false"
      >
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>

    <div :class="['navbar-menu', { 'is-active': isActive }]">
      <div class="navbar-start">
        <a class="navbar-item">Home</a>
        <a class="navbar-item">Features</a>
        <a class="navbar-item">Pricing</a>
      </div>
      <div class="navbar-end">
        <div class="navbar-item">
          <div class="buttons">
            <a class="button is-primary">Sign up</a>
            <a class="button is-light">Log in</a>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "FixedNavbar",
  props: {
    topOffset: {
      type: String,
      default: "0px", // distance from the top
    },
  },
  data() {
    return {
      isActive: false, // for mobile burger menu
    };
  },
  computed: {
    navbarStyle() {
      // Example using "sticky" positioning so that the nav can be offset
      // If you want a truly fixed navbar, use "position: fixed" instead
      return {
        position: "sticky",
        top: this.topOffset,
        zIndex: 10,
      };
    },
  },
  methods: {
    toggleNavbar() {
      this.isActive = !this.isActive;
    },
  },
};
</script>

<style scoped>
/* If you want a truly fixed navbar:
.navbar {
  position: fixed !important;
  width: 100%;
  top: 0;
  left: 0;
}
*/
.navbar {
  min-height: 4rem;
}
.navbar-brand img {
  height: 3rem;
  max-height: unset;
}

.fh_navbar {
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}
</style>
