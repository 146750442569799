<template>
  <h3 class="fh_home_greetings_container" v-html="welcomeMessage"></h3>
</template>
<script>
import { useAuthStore } from "@/store";
import { computed } from "vue";
export default {
  name: "GreetingMessage",
  setup() {
    const authStore = useAuthStore();
    const welcomeMessage = computed(() => {
      const currentHour = new Date().getHours();
      let greeting = "Welcome";
      if (currentHour < 12) {
        greeting = "Good Morning";
      } else if (currentHour < 18) {
        greeting = "Good Afternoon";
      } else {
        greeting = "Good Evening";
      }
      const userName =
        authStore.isAuthenticated && authStore.user && authStore.user.name
          ? authStore.user.name
          : "Guest";
      return `<span class="findhub_home_welcome_greeting">${greeting}</span><br><span class="findhub_home_welcome_name">${userName}</span>`;
    });
    return {
      welcomeMessage,
    };
  },
};
</script>
<style scoped>
.fh_home_greetings_container {
  color: #163142 !important;
  font-size: 3rem;
  font-weight: 600;
  line-height: 1.125;

  .findhub_home_welcome_name {
    color: aquamarine;
  }
}
.findhub_home_welcome_greeting {
  text-transform: uppercase !important;
}

.findhub_home_welcome_name {
  color: aquamarine;
}

@media (max-width: 600px) {
  .fh_home_greetings_container {
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 1.125;
  }
}
</style>
