import axios from "axios";

import Cookies from "universal-cookie";

// Retrieve the token from localStorage
const storedData = JSON.parse(localStorage.getItem("findhub_spa")) || {};
const token = storedData.authToken; // Get the auth token from findhub_spa
const cookies = new Cookies(); // Create an instance of universal-cookie

export const fetchMessagesForSearchTerm = async (searchTermId) => {
  try {
    const response = await axios.get(
      `api/customer/message/${searchTermId}/latest-message`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-XSRF-TOKEN": cookies.get("XSRF-TOKEN"), // Correct capitalization for CSRF token
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data.data; // Assuming backend returns a list of messages
  } catch (error) {
    // Check if the error is a 404 Not Found
    console.log(error.response.data.message); //
  }
};
export const fetchNotificationsForUser = async () => {
  try {
    const response = await axios.get(
      `api/customer/notification/get-notification?currentLength=0`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-XSRF-TOKEN": cookies.get("XSRF-TOKEN"), // Correct capitalization for CSRF token
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(response.data);
    return response.data[0]; // Assuming backend returns a list of messages
  } catch (error) {
    console.error("Error fetching messages:", error);
    throw error;
  }
};
