<template>
  <WelcomeMessage
    :showModal="isWelcomeMessageVisible"
    @update:showModal="isWelcomeMessageVisible = $event"
  />

  <div class="app-layout app-wrapper">
    <main class="app-main">
      <router-view />
      <LoginModalNew
        v-if="modalStore.showLoginModalNew"
        :isVisible="showLoginModalNew"
        @close="closeLoginModal"
      />
    </main>
    <!-- <footer class="app-footer">
      <FooterHome />
    </footer> -->
  </div>
</template>

<script>
/* eslint-disable */
import { useAuthStore, useModalStore } from "./store";
import FooterHome from "./views/layout/FooterHome.vue";
import { messaging } from "@/services/firebaseService"; // Import your Firebase messaging
import LoginModalNew from './components/LoginModalNew.vue';
import WelcomeMessage from './components/WelcomeMessage.vue';
export default {
  name: "App",
  components: {
    FooterHome,
    LoginModalNew,
    WelcomeMessage
  },
  data() {
    return {
      updateData: null,
      showLoginModalNew: false,
      authStore: useAuthStore(),
      isWelcomeMessageVisible: true,
    };
  },
  setup() {
    const modalStore = useModalStore();
    return { modalStore };
  },

  async beforeMount() {
    const authStore = useAuthStore();
    try {
      await authStore.checkAuthStatus();
    }catch (e) {
      console.log("Error on checking")
    }finally {
    }
  },

  mounted() {
    const authStore = useAuthStore();
    // Ensure the user ID is up to date
    const userId = authStore.user?.id || 1; // Use logged-in user ID or default to 1 for testing
    // Log the current authentication state
    messaging.onMessage((payload) => {
      // console.log("Message received in the foreground: ", payload);
      const notificationTitle = payload.notification?.title;
      const notificationBody = payload.notification?.body;
      const image = payload.notification?.image; // Get the image URL from the notification
      // console.log("Notification Body:", notificationBody); // Log the entire body
      // console.log("Notification Image:", image); // Log the entire body
      // Extract the ID from the notification body and log it
      const id = notificationBody; // Assuming the body contains the ID directly
      // console.log("Extracted ID from notification body:", id);
      // Show toast notification with image if available
      if (image) {
        this.$buefy.toast.open({
          message: `${notificationTitle}: ${notificationBody}`,
          type: 'is-info',
          duration: 5000,
          // Use the mixin to display the image
          onClick: () => {
            this.imageUrl(image); // Call the mixin function with the image URL
          }
        });
      } else {
        this.$buefy.toast.open(`${notificationTitle}: ${notificationBody}`);
      }
    });

  },
  methods: {
    closeLoginModal() {
      this.showLoginModalNew = false;
    },
    openLoginModal() {
      console.log("Open Login Modal");
      this.showLoginModalNew = true;
    },
    showWelcomeMessage() {
      this.isWelcomeMessageVisible = true;
    },
  },
};
</script>

<style lang="scss">

// }

.app-wrapper {
  /* Enforce a max width so content doesn't stretch on ultrawide screens */
  max-width: 1200px;       /* or another max width that suits your design */
  margin: 0 auto;          /* center horizontally */
  padding: 0 1rem;         /* optional padding inside for small screens/gutters */
  box-sizing: border-box;  /* helps keep padding in check */
  /* or hsl(203, 40%, 90%) */
  height: 100vh;
}
.app-main {
  height: 100%;
  display: flex;
  flex-direction: column;
}

@media (max-width: 600px) {
  .app-wrapper {
    padding: 0 0.2rem;
  }
  .app-wrapper {
    
  } 
}


</style>
