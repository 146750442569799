<template>
  <div class="page-container">
    <Nav />
    <div class="main-content">
      <!-- Result Header Component -->
      <ResultHeader
        :termDetails="termDetails"
        :imageUrlPublic="imageUrlPublic"
        :currentSearchTermText="currentSearchTermText"
        :currentMessageText="currentMessageText"
        :currentNotifiedCount="currentNotifiedCount"
        :currentOpenedCount="currentOpenedCount"
        :currentRespondedCount="currentRespondedCount"
        :currentNotAvailableCount="currentNotAvailableCount"
        :formattedDuration="formattedDuration"
        :isMobile="isMobile"
        :isIconVisible="isIconVisible"
        :actionWidth="actionWidth"
        :startTouch="startTouch"
        :moveTouch="moveTouch"
        :endTouch="endTouch"
      />
      <!-- End of Result Header Component -->

      <div class="columns inner-main-content" v-if="selected_term_response">
        <template v-if="!isMobile">
          <!-- Search Responses Component -->
          <SearchResponses
            :showable_results="showable_results"
            :selected_term_response="selected_term_response"
            :isLoading="isLoading"
            :resultSort="resultSort"
            :formatAmount="formatAmount"
            :onClickEnquiry="onClickEnquiry"
            :packs="packs"
            :icons="icons"
            :sizes="sizes"
          />
          <!-- End of Search Responses Component -->

          <!-- Selected Response Card Component -->
          <div class="column">
            <SelectedResponseCard
              v-if="selected_term_response"
              :selected_term_response="selected_term_response"
              :term="termDetails"
            />
          </div>
          <!-- End of Selected Response Card Component -->

          <!-- Enquiry Chat Component -->
          <div class="column" v-if="selected_term_response">
            <EnquiryChat
              :sellerResponse="selected_term_response"
              :term="termDetails"
            />
          </div>
          <!-- End of Enquiry Chat Component -->
        </template>
        <template v-else>
          <div class="box fh_mb-search_result_inner-tab">
            <b-tabs v-model="mobileCurrentView" position="is-centered">
              <!-- Mobile Search Responses Component -->
              <b-tab-item label="Results" icon="mdi mdi-list-box">
                <MobileSearchResponses
                  :showable_results="showable_results"
                  :selected_term_response="selected_term_response"
                  :onClickEnquiry="onClickEnquiry"
                  :isMobile="isMobile"
                />
              </b-tab-item>
              <!-- End of Mobile Search Responses Component -->

              <!-- Mobile Selected Response Card Component -->
              <b-tab-item label="Estimation" icon="mdi mdi-map">
                <MobileSelectedResponseCard
                  v-if="selected_term_response"
                  :selected_term_response="selected_term_response"
                  :term="termDetails"
                />
              </b-tab-item>
              <!-- End of Mobile Selected Response Card Component -->

              <!-- Mobile Enquiry Chat Component -->
              <b-tab-item label="Chat" icon="mdi mdi-chat">
                <MobileEnquiryChat
                  :sellerResponse="selected_term_response"
                  :term="termDetails"
                />
              </b-tab-item>
              <!-- End of Mobile Enquiry Chat Component -->
            </b-tabs>
          </div>
        </template>
      </div>
      <div class="columns inner-main-content empty-response" v-else>
        <!-- No Response Component -->
        <NoResponse
          :smsNotification="termDetails.sms_notification_requested"
          :inAppNotification="termDetails.in_app_notification"
          @toggle-sms-notification="toggleSmsNotification"
          @toggle-in-app-notification="toggleInAppNotification"
        />
        <!-- End of No Response Component -->
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import {
  useSearchStore,
  useAuthStore,
  useCartStore,
  useMessageStore,
} from "@/store";
import { VueSpinnerHourglass } from "vue3-spinners";
import { defineAsyncComponent, toRaw } from "vue";
import io from "socket.io-client";
import Nav from "@/components/Nav.vue";
import moment from "moment-timezone"; // Import moment-timezone for time zone handling


const ResultHeader = defineAsyncComponent(() =>
  import("@/components/common/searchresult/ResultHeader.vue")
);
const SearchResponses = defineAsyncComponent(() =>
  import("@/components/desktop/searchresult/SearchResponses.vue")
);
const MobileSearchResponses = defineAsyncComponent(() =>
  import("@/components/mobile/searchresult/MobileSearchResponses.vue")
);
const NoResponse = defineAsyncComponent(() =>
  import("@/components/common/NoResponse.vue")
);

const PageLoader = defineAsyncComponent(() =>
  import("@/components/PageLoader.vue")
);
const SelectedResponseCard = defineAsyncComponent(() =>
  import("@/components/desktop/searchresult/SelectedResponseCard.vue")
);
const MobileSelectedResponseCard = defineAsyncComponent(() =>
  import("@/components/mobile/searchresult/MobileSelectedResponseCard.vue")
);

const EnquiryChat = defineAsyncComponent(() =>
  import("@/components/desktop/chat/EnquiryChat.vue")
);
const MobileEnquiryChat = defineAsyncComponent(() =>
  import("@/components/mobile/chat/MobileEnquiryChat.vue")
);

export default {
  props: {
    searchterm_id: {
      type: String,
      required: true,
    },
  },
  components: {
    EnquiryChat,
    MobileEnquiryChat,
    PageLoader,
    VueSpinnerHourglass,
    SelectedResponseCard,
    MobileSelectedResponseCard,
    Nav,
    ResultHeader,
    SearchResponses,
    MobileSearchResponses,
    NoResponse,
  },
  data() {
    return {
      searchResults: [], // Initialize searchResults
      // searchtermIdFromRoute: this.$route.params.searchterm_id, // Get searchterm_id from route params
      searchStore: useSearchStore(), // Assign searchStore to this
      cartStore: useCartStore(), // Assign cartStore to this
      messageStore: useMessageStore(), // Assign messageStore to this
      termDetails: {
        responses: [],
      },
      searchStatus: "searching",
      statusCollapse: false,
      rate: 4.6,
      maxs: 5,
      sizes: "",
      packs: "mdi",
      icons: "star",
      selected_term_response: null,
      term_id: this.$route.params.id,
      product_listing: true,
      isLoading: false,
      isFullPage: false,
      sms_notification_requested: false,
      in_app_notification: true,
      enquiries: {
        loading: true,
        data: [],
        pagination: {},
      },
      cart: null,
      product_detail: null,
      sort_order: 2,
      selectedResponseExpiryTimer: {
        value: 0,
        intervalId: null,
      },
      meta: {
        actions: {
          onPageLoad: {
            loading: false,
          },
          onProceedAction: {
            loading: false,
          },
        },
      },
      resultSort: "distance_asc",
      cart: [],
      mobileCurrentView: 0,
      checkboxValue: false, // Add a data property for the checkbox
      timer: null, // Timer property
      timerStopped: false, // Flag to indicate if the timer is stopped
      currentTime: moment(), // Add a reactive property for the current time
      touchStartX: 0,
      touchEndX: 0,
      actionWidth: ".2rem", // Reac
    };
  },
  computed: {
    search_results_status: function () {
      if (this.searchResults.responses && this.searchResults.responses.le > 0) {
        return "SELLERS_NOT_FOUND";
      }
      return "SELLERS_FOUND";
    },
    search_status: function () {
      if (this.searchResults.status === "cancelled") {
        return "Cancelled";
      }
      return "Searching";
    },
    isIconVisible() {
      return parseFloat(this.actionWidth) > 1.5; // Check if width is greater than 1.5rem
    },
    showable_results() {
      const responses = this.termDetails.responses;

      // Sort based on the selected resultSort
      return responses.sort((a, b) => {
        if (this.resultSort === "distance_asc") {
          return (a.distance || 0) - (b.distance || 0); // Sort by distance (near to far)
        } else if (this.resultSort === "distance_desc") {
          return (b.distance || 0) - (a.distance || 0); // Sort by distance (far to near)
        } else if (this.resultSort === "price_asc") {
          return (a.price || 0) - (b.price || 0); // Sort by price (low to high)
        } else if (this.resultSort === "price_desc") {
          return (b.price || 0) - (a.price || 0); // Sort by price (high to low)
        }
        return 0; // No sorting
      });
    },
    isMobile() {
      return window.innerWidth < 768; // Adjust the breakpoint as needed
    },

    selected_response_timer_display: function () {
      if (this.selectedResponseExpiryTimer.value > 0) {
        const diff = moment.duration(
          this.selectedResponseExpiryTimer.value,
          "seconds"
        );
        return `${diff.days()}d ${diff.hours()}h ${diff.minutes()}m`;
      }
      return "0 seconds";
    },
    formattedDuration() {
      // Check if the search status is cancelled
      if (this.termDetails.status === "cancelled") {
        return "Cancelled"; // Show "Cancelled" if the status is cancelled
      }

      if (this.timerStopped) {
        return "Stopped"; // Show "Stopped" if the timer is stopped
      }

      const localTime = moment(this.termDetails.created_at); // Use the stored local time directly
      // const currentTimeInLocal = moment(); // Get current time in local time zone (Asia/Dubai)
      // this.currentTime = moment();

      // Ensure duration is non-negative
      const duration = moment.duration(this.currentTime.diff(localTime));
      if (duration.asMilliseconds() < 0) {
        return "Time is in the future"; // Handle future time case
      }

      const hours = Math.floor(duration.asHours());
      const minutes = duration.minutes();
      const seconds = duration.seconds();

      return `${hours}:${minutes}:${seconds}`; // Format as "Xh Ym Zs"
    },
    dynamicStatusMessage() {
      const localTime = moment
        .utc(this.termDetails.created_at)
        .tz("Asia/Dubai");
      const duration = moment.duration(this.currentTime.diff(localTime));
      const minutesElapsed = duration.asMinutes();

      if (this.termDetails.responses.length > 0) {
        return "We received below response, still trying to get more.";
      } else if (minutesElapsed <= 5) {
        return "We are contacting";
      } else if (
        minutesElapsed > 5 &&
        minutesElapsed < 10 &&
        this.termDetails.responses.length === 0
      ) {
        return "No Responses received, you can still wait or set notification option below, we will notify you when a response is received from seller";
      }
      return "Status unknown"; // Default message if none of the conditions are met
    },
    spinnerSize() {
      return window.innerWidth < 768 ? 16 : 30; // Smaller size for mobile
    },
    currentMessageText() {
      const currentMessage = this.messageStore.currentMessages;
      return currentMessage && currentMessage.message && currentMessage.message
        ? currentMessage.message
        : "No messages available for this search term.";
    },
    currentNotifiedCount() {
      const currentMessage = this.messageStore.currentMessages;
      return currentMessage &&
        currentMessage.message &&
        currentMessage.notified_count
        ? currentMessage.notified_count
        : 0;
    },
    currentRespondedCount() {
      const currentMessage = this.messageStore.currentMessages;
      return currentMessage &&
        currentMessage.message &&
        currentMessage.responded_count
        ? currentMessage.responded_count
        : 0;
    },
    currentOpenedCount() {
      const currentMessage = this.messageStore.currentMessages;
      return currentMessage &&
        currentMessage.message &&
        currentMessage.opened_count
        ? currentMessage.opened_count
        : 0;
    },
    currentNotAvailableCount() {
      const currentMessage = this.messageStore.currentMessages;
      return currentMessage &&
        currentMessage.message &&
        currentMessage.not_available_count
        ? currentMessage.not_available_count
        : 0;
    },
    currentSearchTermText() {
      const currentMessage = this.messageStore.currentMessages;
      return currentMessage &&
        currentMessage.message &&
        currentMessage.search_term
        ? currentMessage.search_term
        : this.termDetails.term;
    },
  },
  async created() {
  
    // this.interval = setInterval(() => this.fetchSearchResponses(), 10000)
    this.isLoading = true;
    this.initializeWebSocket();
    await this.fetchSearchResponses();
  },

  async mounted() {
    
    window.addEventListener("resize", this.handleResize);
    this.handleResize(); // Check size on mount

    // Call the checkCart method after the page has loaded
    // await this.checkCart();
    this.startTimer(); // Start the timer when the component is mounted
    try {
      // Load messages asynchronously
      await this.messageStore.loadMessages(this.searchterm_id);
      await this.messageStore.loadNotifications();
    } catch (error) {
      console.error("Failed to load messages:", error);
    } finally {
      this.loading = false; // Stop loading once messages are fetched
    }
  },
  beforeUnmount() {
    console.log("SearResult Vue is created, but not yet mounted to DOM");
    const authStore = useAuthStore();
    console.log("Running before created");
    

    if (this.socket) {
      this.socket.disconnect();
    }
    clearInterval(this.timer); // Clear the timer when the component is unmounted
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    async cancelSearch() {
      try {
        const { result, dialog } = await this.$buefy.dialog.confirm({
          title: "Cancel Search",
          message: "Are you sure you want to <b>cancel</b> your search?",
          closeOnConfirm: false,
          hasIcon: true,
          onConfirm: (result, dialog) => {
            dialog.close();
            this.searchStore.cancel(this.searchterm_id);
            this.searchStatus = "cancelled";
            this.stopTimer(); // Stop the timer
            this.timerStopped = true; // Set the flag to true
          },
        });
      } catch (e) {
        console.log(e);
      }
    },
    startTouch(event) {
      this.touchStartX = event.touches[0].clientX; // Store the starting X position
      this.actionWidth = "0rem"; // Reset width when touch starts
    },
    moveTouch(event) {
      this.touchEndX = event.touches[0].clientX; // Store the current X position
    },
    endTouch() {
      if (this.touchStartX - this.touchEndX > 50) {
        // Check if the swipe is more than 50px
        this.actionWidth = "5rem"; // Expand the width to show the action
        setTimeout(() => {
          this.cancelSearch(); // Call your delete function after a short delay
          this.actionWidth = ".1rem"; // Reset width after action
        }, 500); // Adjust the delay as needed
      }
    },
    handleResize() {
      this.$forceUpdate(); // Force re-render to update the component based on screen size
    },
    onSortChange() {
      console.log(this.resultSort);
    },
    initializeWebSocket() {
      // Replace with the URL of your Node.js server
      const currentUser = this.$currentUser();
      this.socket = io(process.env.VUE_APP_WEBSOCKET_URL);

      // Subscribe to updates for a specific search term ID
      this.socket.emit("subscribeToSearchTerm", this.searchterm_id);
      this.socket.emit("subscribeToSearchTermMessages", 
        this.searchterm_id,
        this.currentUser?.id || null,
        "WEB_CUSTOMER",
        this.$currentUser()?.name || "Guest"
      );

      // Listen for response updates from the server
      this.socket.on("SearchTermResponseUpdate", (data) => {
        console.log("Received search term response update:", data);

        this.updateData = data;

        // Check if data.id exists in termDetails.responses
        const exists = this.termDetails.responses.some(
          (response) => response.id === data.id
        );

        if (!exists) {
          this.termDetails.responses.push(data);
          this.isLoading = false;
        }

        // Update selected_term_response if applicable
        if (
          this.showable_results.length > 0 &&
          this.selected_term_response == null
        ) {
          this.selected_term_response = this.showable_results[0];
        } else if (this.selected_term_response !== null) {
          // Find the ID from the results and set it to selected_term_response
          this.selected_term_response = _.find(this.showable_results, {
            id: this.selected_term_response.id,
          });
        }

        // Add toast notification
        this.$buefy.toast.open({
          message: "New response received!",
          type: "is-success",
          duration: 5000,
        });
      });

      // Listen for message updates from the server
      this.socket.on("SearchTermMessageUpdate", (data) => {
        console.log("Received search term message update:", data);

        // Use Pinia store to update the current message
        this.messageStore.setCurrentMessage(data);

        // Add toast notification for new messages
        this.$buefy.toast.open({
          message: `New message received: ${data.message}`,
          type: "is-info",
          duration: 5000,
        });
      });
    },

    logSearchResults() {
      console.log("Testing ......");
      console.log(this.searchResults);
      // this.searchResults.responses.forEach(response => {
      //   console.log(response);
      // });
    },
    onLoaderCancel() {
      // Handle cancellation of the loader
      this.meta.actions.onPageLoad.loading = false;
      console.log("Loader was canceled");
    },
    async markForEnquiry(stResponse) {
      try {
        // stResponse._meta.actions.onProceed.loading = true
        await SearchService.markForEnquiry(stResponse.id);
      } catch (e) {
        this.errors = e.response;
        console.log("this.errors", e.response);
      }
    },
    onClickEnquiry(term) {
      console.log(term);
      this.mobileCurrentView = 1;
      this.selected_term_response = term;
    },
    async fetchUserData() {
      const authStore = useAuthStore();
      const userData = await authStore.getUserDataFromLocalStore(); // Call the new method
      const userId = userData ? userData.id : null; // Safely access user ID

      // Fetching logic
      this.fetchSearchResults(userData);
    },
    async fetchSearchResults(userData) {
      this.termDetails = await this.searchStore.retriveQuickSearchResults(
        userData.id,
        parseInt(this.searchterm_id)
      );
    },
    async fetchSearchResponses() {
      this.isLoading = true;
      if (
        !this.termDetails.responses ||
        this.termDetails.responses.length === 0
      ) {
        const response = await this.searchStore.viewSearchTerm(
          this.searchterm_id
        );
        this.termDetails = response;
        if (this.termDetails.responses.length > 0) {
          this.isLoading = false;
        }
        // Select the first response as default when there are results
        if (
          this.showable_results.length > 0 &&
          this.selected_term_response == null
        ) {
          this.selected_term_response = this.showable_results[0];
        } else if (this.selected_term_response !== null) {
          // find the ID from the results and set to the selected_term_response
          this.selected_term_response = _.find(this.showable_results, {
            id: this.selected_term_response.id,
          });
        }
      } else {
        this.isLoading = true;
        // You might want to handle the response here as well
      }
    },
    getCurrentUserId() {
      // Access the global currentUser
      const currentUserId = this.$currentUser().id;

      if (currentUserId) {
        console.log("Current User ID:", currentUserId);
      } else {
        console.log("User is not authenticated");
      }
    },
    fetchTermInfo() {
      if (this.termDetails.responses.length > 0) {
        this.selected_term_response = this.termDetails.responses[0];
        console.log("Selected the first response from termDetails.responses");
      } else if (this.selected_term_response !== null) {
        // find the ID from the results and set to the selected_term_response
        this.selected_term_response = _.find(this.showable_results, {
          id: this.selected_term_response.id,
        });
        console.log(
          "Found the ID from the results",
          this.selected_term_response
        );
      }
    },

    async toggleSmsNotification() {
      console.log("SMS notification Toggled");
      this.termDetails.sms_notification_requested =
        !this.termDetails.sms_notification_requested;
      const data = {
        sms_notification_requested: this.termDetails.sms_notification_requested,
        in_app_notification: this.termDetails.in_app_notification,
      };
      const response = await this.searchStore.update(this.searchterm_id, data);
      console.log(response);

      const message = this.termDetails.sms_notification_requested
        ? "You have successfully set SMS notification for all responses"
        : "You have decided not to receive SMS for responses";
      this.$buefy.dialog.alert(message);
    },
    async toggleInAppNotification() {
      console.log("In-App notification Toggled");
      this.termDetails.in_app_notification =
        !this.termDetails.in_app_notification;
      const data = {
        sms_notification_requested: this.termDetails.sms_notification_requested,
        in_app_notification: this.termDetails.in_app_notification,
      };
      const response = await this.searchStore.update(this.searchterm_id, data);
      console.log(response);
      const message = this.termDetails.in_app_notification
        ? "You have successfully set In-App notification for all responses"
        : "You have decided not to receive In-App notifications for responses";
      this.$buefy.dialog.alert(message);
    },
    startTimer() {
      this.timer = setInterval(() => {
        this.currentTime = moment(); // Update currentTime every second
      }, 1000); // Update every second
    },
    stopTimer() {
      clearInterval(this.timer); // Clear the timer
      this.timer = null; // Reset the timer property
    },
  },
};
</script>

<style>
.term_response {
  border-radius: 5px;
}

.response_list_rating {
  margin-bottom: 0px;
}

.no-bottom-margin {
  margin-bottom: 0 !important;
}

.custom-icon {
  height: 2rem !important;
  width: 2rem !important;
}

.fh_search_results_icons-tile {
  display: flex;
  width: 72px;
  flex-direction: column;
  background-color: #f8f6f6;
  border-radius: 5px;
  transition: transform 0.3s ease;
  padding: 0.2rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-right: 8px;
  align-items: center;
  font-size: 0.8rem;
}


.fh_search_result_header-main-column-box {
  display: flex;
}

.fh_search_result_header-main-column-box_close {
  background-color: #00a991;
  transition: width 0.3s ease;
  overflow: hidden;
  border-radius: 5px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.empty-response {
  justify-content: center;
  align-items: center;
}

.main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 1rem 1rem;
  width: 100%;
  margin: 0 auto;
}

.inner-main-content {
  flex: 1;
  display: flex;
}

.fh_results_cards {
  height: 95%;
}

.fh_search_result_header_status-tiles {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  gap: 0.2rem;
}

.fh_search_result_header_status-tile {
  height: 3.5rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: #f8f6f6;
  padding: 1rem;
  min-width: 7rem;
  flex-direction: column;
}

.fh_search_result_header_status-tile-label {
  font-size: 0.75rem;
}

.fh_search_result_header_status-tile-count {
  font-weight: 600;
}

.fh_search_result_header_status-tile-label {
  font-size: 0.75rem;
}

.fh_search_result_header_status-tile-count {
  font-weight: 600;
}

.fh_search_result_header_status-tile-label {
  font-size: 0.75rem;
}

.fh_search_result_header_status-tile-count {
  font-weight: 600;
}

.fh_search_result_result_banner-notification {
  display: flex;
  position: relative;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.fh_search_result-no_response-box {
  padding: 6rem 3rem;
  text-align: center;
}

.fh_search_result-no_response-notification-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
}

.fh_mb-search_result_inner-results-selector {
  width: 100%;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.fh_result_settings {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fh_result_settings-setting {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.fh_result_settings-setting-notification {
  display: flex;
  align-items: center;
}

.fh_result_settings-message {
  background-color: #fff0bc;
  padding: 0.2rem 0.5rem;
  font-weight: 600;
  font-style: italic;
}

.fh_result_settings-setting-notification-img {
  max-width: 2.5rem;
}

.fh_mb-search_result_inner-result {
  position: relative;
}

.fh_search_result-list-active {
  position: relative;
}

/* Unused CSS classes moved to the bottom for review */
.fh_search_result-list-active::after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 0.1rem;
  background-color: #00a991;
}

/* Media Queries */
@media (max-width: 768px) {
  .main-content {
    padding: 0.5rem;
    flex-direction: column;
  }



  .fh_search_result_header_status-tiles {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    justify-content: flex-start;
    align-items: center;
  }

  .fh_search_result_header_status-tile {
    height: 3rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background-color: #f8f6f6;
    padding: 0.6rem 0.8rem;
    min-width: 4.5rem;
    flex-direction: column;
    line-height: 1rem;
  }

  .fh_search_result_header_status-tile-label {
    font-size: 0.7rem;
  }

  .fh_search_result_header_status-tile-count {
    font-weight: 600;
    font-size: 1rem;
  }

  .inner-main-content {
    padding: 0 0.75rem;
  }

  .fh_mb-search_result_inner-tab {
    width: 100%;
    height: 60vh;
    padding: 0;
  }

  .fh_mb-search_result_inner-result_content {
    display: flex;
    justify-content: space-between;
  }

  .fh_mb-search_result_inner-result_content-icons {
    display: flex;
  }

  .fh_result_settings {
    padding: 0.8rem;
    margin-bottom: 0 !important;
  }

  .fh_result_settings-setting-notification-img {
    max-width: 1.5rem;
  }


  .fh_mb-search_result_inner-tab section {
    padding: 0.5rem !important;
  }

  .fh_mb-search_result_inner-result:not(:last-child) {
    margin-bottom: 0.5rem !important;
  }

  .fh_mb-search_result_inner-result-result {
    padding: 0.75rem;
  }

  .fh_mb-search_result_inner-result_main {
    margin-bottom: 0 !important;
    align-items: center;
  }

  .settings-tile {
    margin-left: auto;
    font-size: 1.6rem;
  }
}

@media (min-width: 1200px) {
  .main-content {
    width: 70vw;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .main-content {
    width: 70vw;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .main-content {
    width: 70vw;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .main-content {
    width: 100%;
  }
}

@media (max-width: 575px) {
  .main-content {
    width: 100%;
  }
}

/* Unused CSS classes for review */
.fh_search_result-list-active {
  /* Check if this class is used */
}
</style>
